import { render, staticRenderFns } from "./LiveChatBroadcastCard.vue?vue&type=template&id=6d8d0dd4&"
import script from "./LiveChatBroadcastCard.vue?vue&type=script&lang=js&"
export * from "./LiveChatBroadcastCard.vue?vue&type=script&lang=js&"
import style0 from "./LiveChatBroadcastCard.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VFlex,VIcon,VLayout,VList,VListTile,VListTileTitle,VMenu,VSwitch,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/Users/dkurbatov/Documents/TGG/go-game-platform/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6d8d0dd4')) {
      api.createRecord('6d8d0dd4', component.options)
    } else {
      api.reload('6d8d0dd4', component.options)
    }
    module.hot.accept("./LiveChatBroadcastCard.vue?vue&type=template&id=6d8d0dd4&", function () {
      api.rerender('6d8d0dd4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Lobby/LiveChatBroadcastCard.vue"
export default component.exports