var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "RoomDetailLayout",
    { staticClass: "lobby-broadcast" },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "justify-center": "" } },
        [
          _c("v-flex", { staticClass: "d-flex", attrs: { shrink: "" } }, [
            _vm.isEditable
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.title,
                      expression: "title",
                    },
                  ],
                  staticClass: "main-title",
                  attrs: { type: "text", placeholder: "Title" },
                  domProps: { value: _vm.title },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.title = $event.target.value
                    },
                  },
                })
              : !!_vm.title
              ? _c("div", { staticClass: "main-title" }, [
                  _vm._v(" " + _vm._s(_vm.title) + " "),
                ])
              : _vm._e(),
            _c(
              "div",
              [
                _vm.$_LiveChatMixin_isUserBroadcasting
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "RtbControlButton",
                                    _vm._g(
                                      {
                                        staticClass:
                                          "pink--text ml-auto lobby-broadcast__exit-broadcast-btn",
                                        attrs: { sm: "" },
                                        on: { click: _vm.disconnect },
                                      },
                                      on
                                    ),
                                    [
                                      _c("SvgIcon", {
                                        attrs: {
                                          name: "exit",
                                          width: "26",
                                          height: "26",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2268727208
                        ),
                      },
                      [
                        _c("span", { staticClass: "bottom" }, [
                          _vm._v(" Leave Broadcast "),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c(
            "v-flex",
            { attrs: { shrink: "" } },
            [
              _c(
                "v-layout",
                { attrs: { "align-center": "" } },
                [
                  _c("v-flex", [
                    _vm.isEditable
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.description,
                              expression: "description",
                            },
                          ],
                          staticClass: "lobby-broadcast__description",
                          attrs: { type: "text", placeholder: "Description" },
                          domProps: { value: _vm.description },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.description = $event.target.value
                            },
                          },
                        })
                      : !!_vm.description
                      ? _c(
                          "p",
                          { staticClass: "lobby-broadcast__description" },
                          [_vm._v(" " + _vm._s(_vm.description) + " ")]
                        )
                      : _vm._e(),
                  ]),
                  _vm.isEditable
                    ? _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "offset-y": "",
                                "nudge-width": 100,
                                "close-on-content-click": true,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var action = ref.on
                                      return [
                                        _c(
                                          "RtbControlButton",
                                          _vm._g(
                                            {
                                              staticClass: "ml-1",
                                              attrs: { sm: "" },
                                            },
                                            Object.assign({}, action)
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { dark: "", small: "" },
                                              },
                                              [_vm._v("more_vert")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1058262625
                              ),
                            },
                            [
                              _c(
                                "v-list",
                                { attrs: { dark: "" } },
                                [
                                  _c(
                                    "v-list-tile",
                                    [
                                      _c("v-list-tile-title", [
                                        _c("h3", [_vm._v("Broadcast")]),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm.isHost
                                    ? _c(
                                        "v-list-tile",
                                        [
                                          _c(
                                            "v-list-tile-title",
                                            [
                                              _c("v-switch", {
                                                staticClass: "lobby-switch",
                                                staticStyle: {
                                                  margin: "0",
                                                  padding: "0",
                                                  "font-size": "14px",
                                                },
                                                attrs: {
                                                  disabled: _vm.working,
                                                  flat: "",
                                                  label: "Force fullscreen",
                                                },
                                                model: {
                                                  value: _vm.fullscreen,
                                                  callback: function ($$v) {
                                                    _vm.fullscreen = $$v
                                                  },
                                                  expression: "fullscreen",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-list-tile",
                                    {
                                      attrs: { disabled: _vm.working },
                                      on: { click: _vm.end },
                                    },
                                    [
                                      _c(
                                        "v-list-tile-title",
                                        { staticStyle: { color: "#ff4060" } },
                                        [_vm._v(" End Broadcast ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("LiveChatBroadcast"),
          _c(
            "v-flex",
            { attrs: { shrink: "", "mt-4": "" } },
            [
              _c(
                "v-layout",
                { staticStyle: { "justify-content": "space-between" } },
                [
                  _vm.isHost && _vm.isChimeStream
                    ? _c(
                        "RtbControlButton",
                        {
                          attrs: { variant: "outline", danger: "" },
                          on: {
                            click: function ($event) {
                              _vm.isBroadcastHidden = true
                            },
                          },
                        },
                        [
                          _c("SvgIcon", {
                            staticClass: "mr-1",
                            attrs: {
                              name: "times-regular",
                              width: "16",
                              height: "16",
                            },
                          }),
                          _vm._v(" Close Broadcast "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-flex",
                    { attrs: { grow: "", "pr-4": "" } },
                    [
                      _vm.$_LiveChatMixin_isUserBroadcasting
                        ? _c(
                            "VolumeSlider",
                            {
                              model: {
                                value: _vm.$_LiveChatMixin_volume,
                                callback: function ($$v) {
                                  _vm.$_LiveChatMixin_volume = $$v
                                },
                                expression: "$_LiveChatMixin_volume",
                              },
                            },
                            [_vm._v(" Vol. ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { shrink: "", "ml-2": "" } },
                    [
                      _vm.isJoinable
                        ? _c(
                            "RtbControlButton",
                            {
                              attrs: {
                                disabled: _vm.working,
                                variant: "outline",
                                danger: "",
                              },
                              on: { click: _vm.join },
                            },
                            [
                              _c("SvgIcon", {
                                staticClass: "mr-1",
                                attrs: {
                                  name: "sign-in-regular",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                              _vm._v(" Join "),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { shrink: "", "ml-2": "" } },
                    [
                      _c(
                        "RtbControlButton",
                        {
                          attrs: {
                            disabled: _vm.working,
                            variant: "outline",
                            danger: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("show")
                            },
                          },
                        },
                        [
                          _c("SvgIcon", {
                            attrs: {
                              name: "fullscreen",
                              width: "20",
                              height: "20",
                            },
                          }),
                          _vm._v(" Expand Video "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isEditable
            ? _c(
                "v-flex",
                { attrs: { shrink: "", "mt-4": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    _vm._l(_vm.users, function (user) {
                      return _c(
                        "v-flex",
                        {
                          key: user.id,
                          staticClass: "lobby-broadcast__user",
                          class: {
                            "lobby-broadcast__user--selected":
                              _vm.selectedUserID === user.id,
                            "lobby-broadcast__user--selectable":
                              user.id !== _vm.userID,
                          },
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { column: "" } },
                            [
                              _c("LobbyPlayer", {
                                attrs: {
                                  player: user,
                                  imageURL: user.image,
                                  userID: user.id,
                                },
                                on: { onMouseOver: _vm.onMouseOver },
                                nativeOn: {
                                  mouseleave: function ($event) {
                                    return _vm.onMouseLeave(user)
                                  },
                                },
                              }),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "lobby-broadcast__username",
                                  attrs: { "mt-1": "", "text-xs-center": "" },
                                },
                                [
                                  _vm._o(
                                    _c("user-name", {
                                      attrs: {
                                        firstname: user.firstname,
                                        lastname: null,
                                      },
                                    }),
                                    0,
                                    user.id
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "popup" } },
        [
          !!_vm.selectedUserID
            ? _c(
                "v-layout",
                {
                  key: "user-profile-" + _vm.selectedUserID,
                  staticClass: "lobby-broadcast__popup",
                  class: _vm.popupClass,
                  style: { left: _vm.x + "px", top: _vm.y + "px" },
                  attrs: { "align-center": "" },
                  on: {
                    mouseleave: function ($event) {
                      if ($event.target !== $event.currentTarget) {
                        return null
                      }
                      _vm.selectedUserID = null
                    },
                  },
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      !!_vm.selectedUser
                        ? _c("user-name", {
                            attrs: {
                              firstname: _vm.selectedUser.firstname,
                              lastname: _vm.selectedUser.lastname,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c(
                        "RtbControlButton",
                        {
                          attrs: {
                            disabled: _vm.working,
                            variant: "outline",
                            danger: "",
                          },
                          on: { click: _vm.kick },
                        },
                        [_vm._v(" Remove ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }