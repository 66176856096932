<template>
  <RoomDetailLayout class="lobby-broadcast">
    <v-layout column justify-center>
      <v-flex shrink class="d-flex">
        <input
          class="main-title"
          v-model="title"
          type="text"
          placeholder="Title"
          v-if="isEditable"
        />
        <div class="main-title" v-else-if="!!title">
          {{ title }}
        </div>
        <div>
          <v-tooltip bottom v-if="$_LiveChatMixin_isUserBroadcasting">
            <template v-slot:activator="{ on }">
              <RtbControlButton
                sm
                v-on="on"
                @click="disconnect"
                class="pink--text ml-auto lobby-broadcast__exit-broadcast-btn"
              >
                <SvgIcon name="exit" width="26" height="26" />
              </RtbControlButton>
            </template>
            <span class="bottom"> Leave Broadcast </span>
          </v-tooltip>
        </div>
      </v-flex>
      <v-flex shrink>
        <v-layout align-center>
          <v-flex>
            <input
              class="lobby-broadcast__description"
              v-model="description"
              v-if="isEditable"
              type="text"
              placeholder="Description"
            />
            <p class="lobby-broadcast__description" v-else-if="!!description">
              {{ description }}
            </p>
          </v-flex>
          <v-flex shrink v-if="isEditable">
            <v-menu offset-y :nudge-width="100" :close-on-content-click="true">
              <template #activator="{ on: action }">
                <RtbControlButton sm class="ml-1" v-on="{ ...action }">
                  <v-icon dark small>more_vert</v-icon>
                </RtbControlButton>
              </template>

              <v-list dark>
                <v-list-tile>
                  <v-list-tile-title> <h3>Broadcast</h3> </v-list-tile-title>
                </v-list-tile>
                <v-list-tile v-if="isHost">
                  <v-list-tile-title>
                    <v-switch
                      class="lobby-switch"
                      :disabled="working"
                      flat
                      v-model="fullscreen"
                      label="Force fullscreen"
                      style="margin: 0; padding: 0; font-size: 14px"
                    />
                  </v-list-tile-title>
                </v-list-tile>
                <v-list-tile :disabled="working" @click="end">
                  <v-list-tile-title style="color: #ff4060">
                    End Broadcast
                  </v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-flex>
        </v-layout>
      </v-flex>

      <LiveChatBroadcast />

      <v-flex shrink mt-4>
        <v-layout style="justify-content: space-between">
          <RtbControlButton
            v-if="isHost && isChimeStream"
            @click="isBroadcastHidden = true"
            variant="outline"
            danger
          >
            <SvgIcon name="times-regular" width="16" height="16" class="mr-1" />
            Close Broadcast
          </RtbControlButton>
          <v-flex grow pr-4>
            <VolumeSlider
              v-if="$_LiveChatMixin_isUserBroadcasting"
              v-model="$_LiveChatMixin_volume"
            >
              Vol.
            </VolumeSlider>
          </v-flex>
          <v-flex shrink ml-2>
            <RtbControlButton
              v-if="isJoinable"
              @click="join"
              :disabled="working"
              variant="outline"
              danger
            >
              <SvgIcon
                name="sign-in-regular"
                width="16"
                height="16"
                class="mr-1"
              />
              Join
            </RtbControlButton>
          </v-flex>
          <v-flex shrink ml-2>
            <RtbControlButton
              @click="$emit('show')"
              :disabled="working"
              variant="outline"
              danger
            >
              <SvgIcon name="fullscreen" width="20" height="20" />
              Expand Video
            </RtbControlButton>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex shrink mt-4 v-if="isEditable">
        <v-layout justify-center>
          <v-flex
            v-for="user in users"
            :key="user.id"
            class="lobby-broadcast__user"
            :class="{
              'lobby-broadcast__user--selected': selectedUserID === user.id,
              'lobby-broadcast__user--selectable': user.id !== userID
            }"
          >
            <v-layout column>
              <LobbyPlayer
                :player="user"
                :imageURL="user.image"
                :userID="user.id"
                @onMouseOver="onMouseOver"
                @mouseleave.native="onMouseLeave(user)"
              />
              <v-flex mt-1 text-xs-center class="lobby-broadcast__username">
                <user-name
                  v-once
                  :firstname="user.firstname"
                  :lastname="null"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <transition name="popup">
      <v-layout
        align-center
        @mouseleave.self="selectedUserID = null"
        v-if="!!selectedUserID"
        :style="{ left: `${x}px`, top: `${y}px` }"
        class="lobby-broadcast__popup"
        :class="popupClass"
        :key="`user-profile-${selectedUserID}`"
      >
        <v-flex shrink>
          <user-name
            v-if="!!selectedUser"
            :firstname="selectedUser.firstname"
            :lastname="selectedUser.lastname"
          />
        </v-flex>
        <v-flex shrink>
          <RtbControlButton
            @click="kick"
            :disabled="working"
            variant="outline"
            danger
          >
            Remove
          </RtbControlButton>
        </v-flex>
      </v-layout>
    </transition>
  </RoomDetailLayout>
</template>

<script>
import Vue from "vue"
import { mapGetters, mapState } from "vuex"
import { db } from "@/firebase"
import { RtbControlButton } from "@/components/ui"
import RoomDetailLayout from "./RoomDetailLayout.vue"
import VolumeSlider from "@/components/GroupTeams/Misc/VolumeSlider"
import { debounce } from "lodash"
import LiveChatMixin from "@/mixins/LiveChat"
import LobbyPlayer from "@/components/Lobby/Player"
import UserName from "@/components/GroupTeams/Common/User/UserName"
import Collection from "@shared/User"
import GetterTypes from "@/store/getter-types"
import TwilioRoomService from "@/services/stream.service/Room"
import useLobby from "@/use/useLobby"

export default Vue.extend({
  name: "LiveChatBroadcastCard",
  components: {
    RoomDetailLayout,
    RtbControlButton,
    LobbyPlayer,
    UserName,
    LiveChatBroadcast: () => import("@/components/Lobby/LiveChatBroadcast.vue"),
    ImageUploaderNext: () => import("@/components/ImageUploader.vue"),
    VolumeSlider
  },

  mixins: [LiveChatMixin],

  setup() {
    const { isChimeStream, isBroadcastHidden } = useLobby()
    return { isChimeStream, isBroadcastHidden }
  },

  data() {
    return {
      working: false,
      db: db.auxiliary(),
      data: null,
      timeout: null,
      selectedUserID: null,
      popupClass: null,
      x: 0,
      y: 0
    }
  },
  beforeDestroy() {
    this.roomRef?.off("value", this.onRoomUpdate)
    clearTimeout(this.timeout)
  },
  computed: {
    ...mapGetters("auth", ["user", "client", "clientID", "isSuper", "isHost"]),
    ...mapGetters("livechat", ["max", "room", "roomID"]),
    ...mapState(["orgID"]),
    userID() {
      return this.user?.id
    },
    selectedUser() {
      const selectedUserID = this.selectedUserID
      return this.users.find(user => user.id === selectedUserID)
    },
    users() {
      const users = Collection.normalize(this.data?.users)
      const online = this.$store.getters[GetterTypes.ONLINE_CLIENT_USERS]
      const currentUserID = this.userID
      return online
        .filter(user => users.some(({ userID }) => userID === user.id))
        .sort(user => (user.id === currentUserID ? -1 : 1))
    },
    roomRef() {
      const { $_LiveChatMixin_streamRoomID: roomID, clientID: sessionID } = this
      return roomID && this.db.ref(`client/${sessionID}/calls/rooms/${roomID}`)
    },
    isEditable() {
      return this.isHost || this.isVip
    },
    isJoinable() {
      return (
        !this.$_LiveChatMixin_isUserBroadcasting &&
        (this.isVip || this.isSuper || (!this.data?.locked && this.isHost))
      )
    },
    description: {
      get() {
        return this.client?.stream?.description
      },
      set: debounce(function (value) {
        this.db.ref(`clients/${this.clientID}/stream/description`).set(value)
      }, 500)
    },
    title: {
      get() {
        return this.client?.stream?.title
      },
      set: debounce(function (value) {
        this.db.ref(`clients/${this.clientID}/stream/title`).set(value)
      }, 500)
    },
    isVip() {
      return !!this.user?.vip
    },
    fullscreen: {
      get() {
        return !!this.client?.stream.fullscreen
      },
      set(value) {
        this.db.ref(`clients/${this.clientID}/stream/fullscreen`).set(value)
      }
    }
  },
  watch: {
    roomRef: {
      handler(newValue, oldValue) {
        oldValue?.off("value", this.onRoomUpdate)
        newValue?.on("value", this.onRoomUpdate)
      },
      immediate: true
    }
  },
  methods: {
    async kick() {
      this.working = true
      try {
        await TwilioRoomService.kick(
          this.$_LiveChatMixin_streamRoomID,
          this.selectedUserID
        )
      } catch (e) {
        console.error(e)
      }
      this.working = false
    },
    onRoomUpdate(snapshot) {
      this.data = snapshot.val()
    },
    onMouseOver({ userID, $event }) {
      if (userID === this.userID) return
      const rect = $event.target.getBoundingClientRect()
      const { x, y, height, width } = rect
      clearTimeout(this.mouseOverTimeout)
      this.timeout = setTimeout(() => {
        const popupWidth = 280
        this.x = x - popupWidth / 2 + width / 2
        this.y = y - 10 + height + 5
        this.selectedUserID = userID
      }, 500)
    },
    onMouseLeave(user) {
      if (this.userID === user?.id) return
      clearTimeout(this.timeout)
    },
    async join() {
      this.working = true
      try {
        await this.$store.dispatch("livechat/enter", {
          force: true,
          roomID: this.client?.stream?.roomID
        })
      } catch (e) {
        console.error(e)
      }
      this.working = false
    },
    async end() {
      try {
        await this.$confirm({
          message: "Are you sure you would like to end the broadcast",
          buttonColor: "danger"
        })
        this.working = true
        await this.$_LiveChatMixin_stopBroadcast()
        this.working = false
      } catch (error) {}
    },
    async disconnect() {
      try {
        await this.$confirm({
          message: "Are you sure you would like to leave the broadcast",
          buttonColor: "danger"
        })
        await this.$_LiveChatMixin_exitBroadcast()
        this.$store.dispatch("livechat/end")
      } catch (error) {}
    },
    fullscreenToggle() {
      db.auxiliary()
        .ref(`clients/${this.clientID}/stream/fullscreen`)
        .set(!this.fullscreen)
    }
  }
})
</script>

<style lang="scss">
.lobby-switch {
  label {
    font-size: 14px;
    color: #fff !important;
  }
}
.lobby-broadcast {
  position: relative;
  .volume {
    max-width: 220px !important;
  }

  &__popup {
    justify-content: space-around;
    position: fixed;
    left: 200px;
    top: 200px;
    width: 280px;
    height: 80px;
    background-color: #292932;
    border-radius: 10px;
    z-index: 2000;
    box-shadow: 0px 7px 18px rgba(0, 0, 0, 0.6);
    &::before {
      content: "";
      left: 50%;
      width: 10px;
      height: 10px;
      position: absolute;
      background-color: #292932;
      top: -9px;
      transform: rotate(-45deg) translateX(-50%);
    }
  }

  &__user {
    max-width: 86px;
    padding: 4px;

    &--selectable {
      cursor: pointer;
    }

    .video-container:before {
      content: "";
      display: block;
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 0px solid $color-white;
      opacity: 0.5;
      transition: border-width 0.5s;
    }

    &--selected {
      .video-container::before {
        border-width: 40px !important;
      }
    }

    &--selectable:hover {
      .video-container::before {
        border-width: 40px;
      }
    }
  }

  &__username {
    font-size: 14px;
  }

  &__description {
    margin-bottom: 0;
  }

  &__exit-broadcast-btn {
    transform: scaleX(-1);
  }
}
</style>
